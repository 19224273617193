var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c("card-shipping-header", {
        attrs: {
          expanded: _vm.expanded,
          "window-from": _vm.shipping["window_from"],
          "window-to": _vm.shipping["window_to"],
        },
        on: {
          expand: function ($event) {
            _vm.expanded = !_vm.expanded
          },
        },
      }),
      _c(
        "div",
        { staticClass: "w-100p" },
        [
          _vm._l(_vm.preparedListOption.slice(0, 3), function (option) {
            return _c(
              "div",
              { key: option.key },
              [
                option.value
                  ? _c("card-option", {
                      attrs: {
                        field: option.key,
                        type: option.type,
                        title: option.title,
                        description:
                          option.key === "num_auto"
                            ? option.value.toUpperCase()
                            : option.value,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _vm.expanded
              ? _c(
                  "div",
                  { staticClass: "w-100p" },
                  [
                    _vm._l(_vm.preparedListOption.slice(3), function (option) {
                      return _c(
                        "div",
                        { key: option.key },
                        [
                          option.value
                            ? _c("card-option", {
                                key: option.key,
                                attrs: {
                                  type: option.type,
                                  title: option.title,
                                  description:
                                    option.key === "num_auto"
                                      ? option.value.toUpperCase()
                                      : option.value,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "df jc-sb ai-c p-r-16 p-l-16 m-t-16" },
                      [
                        _vm.showDeleteButton
                          ? _c("iq-mobile-button", {
                              attrs: {
                                "button-style":
                                  "padding: 12px; width: 100%; margin-right: 4px; background: #DB6D39; border-color: #DB6D39; color: white",
                                "text-style": "color: white; font-weight: 500",
                                title: "Удалить",
                              },
                              on: {
                                onClick: function ($event) {
                                  return _vm.$emit("emitDelete", _vm.shipping)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("iq-mobile-button", {
                          attrs: {
                            "button-style":
                              "padding: 12px; width: 100%; margin-left: 4px",
                            "text-style": "color: #000000; font-weight: 500",
                            title: "Редактировать",
                          },
                          on: {
                            onClick: function ($event) {
                              return _vm.$emit("emitEdit", _vm.shipping)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("modal-fullscreen-mobile"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }