<template>
  <div class="df ai-fs jc-sb w-100p m-t-12 m-b-12 p-r-16 p-l-16">
    <div class="m-r-12">
      <p
        style="
          color: #606266;
          font-size: 12px;
          font-weight: 400;
          font-family: Roboto, sans-serif;
        "
      >
        {{ title }}
      </p>
    </div>
    <div
      style="
        border: none;
        border-top: 1px dotted #dcdfe6;
        flex: 1 1;
        margin-top: 16px;
        height: 1px;
      "
      class="p-r-3 p-l-3"
    />
    <div v-if="type === 'text'" class="df jc-fe m-l-12">
      <p
        class="ta-l"
        style="
          color: #303133;
          font-size: 12px;
          font-weight: 400;
          font-family: Roboto, sans-serif;
        "
      >
        {{ description }}
      </p>
    </div>
    <div v-if="type === 'status'" class="df jc-fe m-l-12">
      <div class="status" :class="['tag-' + status.icon, statusClass]">
        <i :class="status.icon" />
        <p
          class="fs-12"
          style="
            color: #303133;
            font-size: 12px;
            font-weight: 400;
            font-family: Roboto, sans-serif;
          "
        >
          {{ status.title }}
        </p>
      </div>
    </div>
    <div v-if="type === 'phone'" class="df jc-fe option-phone m-l-12">
      <a
        :href="`tel:${description.trim().replace(/[^+\d]/g, '')}`"
        class="ta-l option-phone"
      >
        {{ phoneRegex(description) }}
      </a>
    </div>
  </div>
</template>

<script>
import { phoneRegex } from '@/core'
import { timeslotStatuses } from '@/views/manager/data/manager'

export default {
  name: 'CardOption',
  components: {},
  props: {
    field: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeslotStatuses,
    }
  },

  computed: {
    status() {
      const truckStatus =
        this.field === 'truck_status'
          ? this.description.split(' ')[0]
          : this.description

      return timeslotStatuses[truckStatus]
    },
    statusClass() {
      return this.field === 'truck_status'
        ? `ts-${this.description.split(' ')[1]}`
        : ''
    },
  },

  methods: {
    phoneRegex,
  },
}
</script>

<style lang="sass" scoped>
.dotted-line
  border: none
  border-top: 1px dotted #DCDFE6
  flex: 1 1
  margin-bottom: 6px
.option-phone
  color: #4CA7D8
  text-decoration: none
  font-size: 12px
  font-weight: 400
  font-family: Roboto, sans-serif

.status
  width: 100%
  border: 1px solid #E9E9EB
  background: #ffffff
  padding: 2px 11px
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  border-radius: 64px
  i
    margin-right: 5px
  &.tag-el-icon-info
    i
      color: #909399
  &.tag-el-icon-success
    background: #F0F9EB
    border: 1px solid #E1F3D8
    i
      color: #67C23A
  &.tag-el-icon-warning
    background: #FAECD8
    border: 1px solid #FAECD8
    i
      color: #E6A23C
  &.tag-el-icon-error
    background: #FEF0F0
    border: 1px solid #FDE2E2
    i
      color: #F56C6C
  &.ts-dropped,
  &.ts-deletedByAdmin,
  &.ts-moved
    text-decoration: line-through
</style>
